<template>
  <li class="border-card">
    <div class="orderList-item">
      <div class="order-summary">
        <div class="summary-content">
          <div class="order-status">
            <div class="order-status-name" :style="{ color: statusColors[data.deliveryStatus] }">
              {{ data.deliveryStatusName }}
            </div>
            <div class="order-count">
              <span class="order-type">
                <span class="order-modal">{{
                  productModels[data.productModel] || data.productModelName
                }}</span>
                <span class="order-cabin">{{ data.productCabinCodeName }}</span>
              </span>
              <span class="total-count">
                <span>{{ data.deliveryNumber }}台</span>

                <span v-if="data.vehicleList.length">
                  <b>|</b>
                  <span v-if="data.deliveryNumber !== data.vehicleList.length"
                    >已发货{{ data.vehicleList.length }}台</span
                  >
                  <span v-else>已全部发货</span>
                </span>
              </span>
            </div>
          </div>
          <div class="order-number">
            <div>订单号</div>
            <div>{{ data.orderNumber }}</div>
          </div>
        </div>
        <div class="summary-vehicle">
          <img :src="vehicleTypes[data.productModelName] || vehicleTypes.X3" alt="" />
        </div>
      </div>
      <div class="vehicle-list" v-if="data.vehicleList.length">
        <div class="list-container">
          <div v-for="item in computedVehicleList" :key="item.vin" class="vehicle-item">
            <div class="vinId">{{ item.vinId }}</div>
            <div v-if="item.deliveryStatus === 5" class="returnOrExchangeTime">
              {{ item.returnOrExchangeTime }} 退货
            </div>
            <div v-if="item.deliveryStatus === 3" class="actualDeliveryDate">
              {{ item.actualDeliveryDate }} 已交付
            </div>
            <div v-if="item.deliveryStatus === 4" class="activationTime">
              {{ item.activationTime }} 已抵达
            </div>
            <div v-if="item.deliveryStatus === 1" class="sendOutDate">
              <div>{{ item.sendOutDate }} 发货</div>
              <div>
                <span>司机</span>
                <span>{{ item.driverPhone }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="data.vehicleList.length > 2 && !expandVehicle"
          class="expand-btn"
          @click="expandVehicle = !expandVehicle"
        >
          展开全部
        </div>
      </div>
      <div class="order-address">
        <div v-if="!expandAddress" class="address-header" @click="expandAddress = !expandAddress">
          <span>收货信息</span>
          <img src="@/assets/faultrReporting/arrow-down.png" alt="" />
        </div>
        <div v-else class="address-header">
          <span>收货信息</span>
        </div>
        <div
          class="address-content"
          v-if="expandAddress && (data.deliveryAddress || data.deliveryContact)"
        >
          <div class="address-detail">{{ data.deliveryAddress }}</div>
          <div v-if="data.deliveryContact && data.deliveryContactPhone" class="address-contact">
            <span>{{ data.deliveryContact }}</span>
            <span>{{ data.deliveryContactPhone }}</span>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import X3 from '@/assets/vehicleType/X3.png';
import X6 from '@/assets/vehicleType/X6.png';

const statusColors = {
  1: '#FE8000',
  2: '#FE8000',
  3: '#73BF00',
  4: '#26C575',
  5: '#436EFF',
  6: '#436EFF',
  7: '#000000',
  8: '#12C6E6',
};

export default {
  props: {
    data: Object,
  },
  data() {
    return {
      limit: 2,
      vehicleTypes: {
        X6,
        X3,
      },
      productModels: {
        B: 'X3',
        A: 'X3',
      },
      statusColors,
      expandVehicle: false,
      expandAddress: true,
    };
  },
  computed: {
    computedVehicleList() {
      return this.expandVehicle
        ? this.data.vehicleList
        : this.data.vehicleList.slice(0, this.limit);
    },
  },
  watch: {
    'data.deliveryStatus': {
      handler(value) {
        this.expandAddress = ![5, 6, 8].includes(value);
      },
      immediate: true,
    },
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.border-card {
  position: relative;
  margin: 14px 0;
  box-sizing: border-box;
  background: white;
  border-radius: 12px;
  margin-bottom: 14px;
  z-index: 2;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: 0;
    z-index: -1;
    border-radius: 12px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, transparent 100%);
  }
}
.orderList-item {
  border-radius: 12px;

  background: linear-gradient(to bottom, rgba(237, 244, 255, 1) 0%, rgba(255, 255, 255, 1) 80px);

  .order-summary {
    padding: 14px;

    display: flex;
    justify-content: space-between;
    .summary-content {
      width: max-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .order-status {
        .order-status-name {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0px;
          line-height: 20px;
        }

        .order-count {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin-top: 6px;
          .order-type {
            display: flex;
            align-items: baseline;
            width: max-content;
            .order-modal {
              font-size: 28px;
              font-weight: 500;
              letter-spacing: 0px;
              color: rgba(0, 0, 0, 1);
              font-family: DINPro;
            }

            .order-cabin {
              margin-left: 4px;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0px;
              color: rgba(0, 0, 0, 1);
            }
          }

          .total-count {
            width: max-content;
            margin-left: 16px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 1);
            display: flex;
            align-items: baseline;
            > span {
              display: flex;
              align-items: baseline;
              &:nth-child(2) {
                font-size: 12px;
                display: flex;
                align-items: baseline;
                color: rgba(151, 152, 171, 1);
                > b {
                  margin: 0 4px;
                }
              }
            }
          }
        }
      }

      .order-number {
        margin-top: 6px;
        > div {
          &:nth-child(1) {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 16px;
            color: rgba(151, 152, 171, 1);
          }

          &:nth-child(2) {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0px;
            line-height: 20px;
            color: rgba(0, 0, 0, 1);
          }
        }
      }
    }
    .summary-vehicle {
      display: flex;
      flex-shrink: 1;
      img {
        max-width: 124px;
        width: 100%;
        aspect-ratio: 1 / 1;
      }
    }
  }

  .vehicle-list {
    // margin: 8px 0 16px 0;
    position: relative;
    padding: 14px;
    padding-top: 0;
    .list-container {
      display: grid;
      align-items: center;
      gap: 8px;
      grid-template-columns: repeat(2, 1fr);

      .vehicle-item {
        height: 76px;
        border-radius: 4px;
        padding: 8px 12px;
        background: rgba(245, 246, 249, 1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .vinId {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 20px;
          color: rgba(0, 0, 0, 1);
        }

        .returnOrExchangeTime {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0px;
          line-height: 16px;
          color: #9798ab;
        }

        .actualDeliveryDate {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0px;
          line-height: 16px;
          color: rgba(67, 110, 255, 1);
        }

        .activationTime {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0px;
          line-height: 16px;
          color: rgba(18, 198, 230, 1);
        }

        .sendOutDate {
          div {
            &:nth-child(1) {
              font-size: 12px;
              font-weight: 600;
              letter-spacing: 0px;
              line-height: 16px;
              color: rgba(38, 197, 117, 1);
            }

            &:nth-child(2) {
              margin-top: 2px;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 16px;
              color: rgba(151, 152, 171, 1);
            }
          }
        }
      }
    }

    .expand-btn {
      margin: 8px 0;
      margin-bottom: 0;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 16px;
      color: rgba(67, 110, 255, 1);
      position: relative;
      &::before {
        position: absolute;
        content: '';
        top: -48px;
        left: 0;
        width: 100%;
        height: 48px;
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }

  .order-address {
    padding: 14px;
    border-top: 1px solid rgba(235, 235, 235, 1);
    .address-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 16px;
        color: rgba(151, 152, 171, 1);
      }

      img {
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
    }

    .address-content {
      margin-top: 6px;
      .address-detail {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 20px;
        color: rgba(82, 83, 110, 1);
        word-break: break-all;
      }

      .address-contact {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 16px;
        color: rgba(82, 83, 110, 1);
        span {
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
